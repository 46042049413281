import React, {useEffect, useState} from 'react'
import {Link, MenuItem, Select, TableCell, TableRow, TextField} from '@material-ui/core'

import {IGroup, IUser} from 'common'
import {Admin} from '../Admin'
import api from '../../services/api'
import styles from "./styles.scss"
import LogoutIcon from "@material-ui/icons/ExitToApp"

interface UserEditDialogProps {
    onValidItem: (user: Partial<IUser>) => void
    user: Partial<IUser> | null
    groups: IGroup[]
}

function UserEditDialog(props: UserEditDialogProps) {
    const [email, setEmail] = useState(props.user?.email || "")
    const [groupName, setGroupName] = useState(props.user?.group?.name || "")
    const [availableGroups, setAvailableGroupsNames] = useState(props.user?.availableGroups || [])
    const setUserValidity = () => {
        if (email.length > 1 && groupName.length > 1) {
            // The current web-app API works with the group only specifying its name
            const newUser = {
                ...props.user,
                email,
                group: { name: groupName },
                availableGroups,
            } as Partial<IUser>

            props.onValidItem(newUser)
        }
    }

    useEffect(setUserValidity, [email, groupName, availableGroups])

    return <div className={styles.form}>
        <TextField
            type='email'
            value={email}
            label='Email'
            margin='normal'
            placeholder='john.doe@example.com'
            onChange={(e) => setEmail(e.target.value)}
        />
        <Select
            label='Group Name'
            placeholder='adalong'
            value={groupName}
            onChange={(e) => setGroupName(e.target.value as string)}
        >
            {props.groups.map((group) => {
                return <MenuItem value={group.name} key={group.name}>
                    {group.name}
                </MenuItem>
            })}
        </Select>

        <Select
            label='Available groups'
            value={availableGroups}
            multiple
            onChange={(e) => setAvailableGroupsNames(e.target.value as IGroup[])}
        >
            {props.groups.map((group) => {
                return <MenuItem value={group._id} key={group._id}>
                    {group.name}
                </MenuItem>
            })}
        </Select>
    </div>
}

function Users() {
    const displayHeader =
        <TableRow>
            <TableCell key='1'>Email</TableCell>
            <TableCell key='2'>Group</TableCell>
            <TableCell key='3'>Creation Date</TableCell>
            <TableCell key='4'>Extension API Key</TableCell>
            <TableCell key='5'/>
            <TableCell key='6'/>
        </TableRow>
    const [groups, setGroups] = useState<IGroup[]>([])

    useEffect(() => {
        api.getGroups({
            filters: {
                name: ""
            },
            options: {
                limit: 1000
            }
        }).then(({groups}) => setGroups(groups))
    }, [])

    const create = (user: Partial<IUser>): Promise<void> => {
        return api.createUser(user).then()
    }

    const update = (user: Partial<IUser>): Promise<void> => {
        if (!user._id) {
            return Promise.reject(new Error('Cannot update user without an _id'))
        }
        return api.updateUser(user._id!, user).then()
    }

    const search = (input: string): Promise<Partial<IUser>[]> => {
        // limit: 300, no pagination for the moment. 
        // It should be reworked when the identity system will be developed.
        return api.getUsers({filters: {name: input}, options: { limit: 300 }})
            .then((res) => res.users)
    }

    const remove = (user: Partial<IUser>): Promise<void> => {
        if (!user._id) {
            return Promise.reject(new Error('Cannot delete user without an _id'))
        }
        return api.removeUser(user._id).then()
    }

    const logAs = (user: Partial<IUser>): void => {
        if (!user._id) {
            throw new Error('Cannot log as user without an _id')
        }
        api.logAsUser(user._id)
            .then((url) => {
                window.open(url, "adalongasadmin")
            })
    }

    const displayLine = (user: Partial<IUser>): JSX.Element[] => {
        return [
            <TableCell key='1'>{user.email}</TableCell>,
            <TableCell key='2'>{user.group?.name}</TableCell>,
            <TableCell key='3'>{user.created_at}</TableCell>,
            <TableCell key='4'>{user.extensionApiKey}</TableCell>,
            <TableCell key='5'>
                <Link
                    className={styles.iconLink}
                    onClick={() => logAs(user)}
                >
                    <LogoutIcon/>
                </Link>
            </TableCell>
        ]
    }

    const displayEditWindow = (user: Partial<IUser> | null, onValidItem: (user: Partial<IUser>) => void): JSX.Element => {
        return <UserEditDialog
            onValidItem={onValidItem}
            user={user}
            groups={groups}
        />
    }

    return <Admin<Partial<IUser>>
        title='Users'
        displayHeader={displayHeader}
        create={create}
        update={update}
        search={search}
        remove={remove}
        getId={(user) => user._id!}
        displayLine={displayLine}
        displayEditWindow={displayEditWindow}
    />
}

export default Users
